body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --mdc-theme-primary: #00f224;
  --mdc-theme-secondary: #292929;
  --mdc-theme-background: #121212;
  --mdc-theme-text-primary-on-background: #ffffff;
  --mdc-theme-text-secondary-on-background: #bfc7c7;
  --mdc-theme-text-disabled-on-background: #575959;
  --mdc-theme-text-primary-on-dark: #ffffff;
  --mdc-theme-text-primary-on-light: #00ce2a;
  --mdc-theme-on-secondary: #121212;
  --mdc-theme-surface: #fafafa;
  --mdc-theme-on-surface: #121212;
  --mdc-theme-error: #df2640;

  --mdc-typography-subtitle1-font-size: 0.95em;
}

/* Overwrites */
.mdc-tab,
body .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  color: var(--mdc-theme-text-primary-on-dark);
}

.mdc-top-app-bar.mdc-top-app-bar--custom {
  max-width: 1152px;
  left: 50%;
  transform: translateX(-50%);
}
.mdc-top-app-bar--custom.mdc-top-app-bar--short-collapsed {
  border-radius: 0 0 24px 24px;
  width: 25%;
  min-width: fit-content;
}
.mdc-top-app-bar--custom .mdc-top-app-bar__row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content max-content;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 56px;
}

.mdc-grid--custom-actions {
  position: fixed;
  width: 100%;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 1;
}
.mdc-grid--custom-actions .mdc-fab {
  pointer-events: all;
  z-index: 2;
}

.mdc-linear-progress--custom {
  position: absolute;
  max-width: 1152px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}
.mdc-linear-progress--custom .mdc-linear-progress__bar-inner {
  border-width: 8px;
}
.mdc-linear-progress--custom .mdc-linear-progress__buffer {
  background-color: var(--mdc-theme-secondary);
}

/* Color fixes */

.mdc-form-field {
  color: var(--mdc-theme-on-surface);
}

/* Layout fixes */

.mdc-menu.mdc-menu-surface {
  white-space: nowrap;
}

.mdc-dialog__container,
.mdc-dialog__surface {
  max-width: 800px;
  width: 100%;
}

.mdc-notched-outline.mdc-notched-outline--upgraded {
  top: 0;
}

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 20px;
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)
  .mdc-text-field__input {
  padding: 12px 16px 14px;
}

.rmwc-circular-progress {
  margin: auto;
}
.rmwc-circular-progress--progressview {
  margin: auto;
  left: calc(50% - 26px);
  top: 60px;
}

.mdc-text-field--file .mdc-text-field__input::-webkit-file-upload-button {
  visibility: hidden;
}
.mdc-text-field--file input {
  opacity: 0;
}
.mdc-text-field--file label {
  right: 20px;
  position: absolute;
  top: 18px;
}

/* Notch */

.mdc-notched-outline .mdc-floating-label.mdc-floating-label--float-above {
  background: var(--mdc-theme-surface);
  padding: 0 5px 0 5px;
}

/* Snippets */

.justify-start {
  margin-right: auto !important;
}

/* Dialog */

.mdc-dialog.mdc-dialog--contract .mdc-dialog__surface,
.mdc-dialog.mdc-dialog--contract .mdc-dialog__container {
  max-width: 900px;
}

.mdc-dialog.mdc-dialog--contract .mdc-data-table__row {
  white-space: normal;
}
